const ATTR_F_NAME = "data-cofi-payable-first-name";
const ATTR_L_NAME = "data-cofi-payable-last-name";
const ATTR_ZIP = "data-cofi-payable-zip";
const ATTR_CC_LAST_FOUR = "input[data-cofi-payable-cc-last-four]";
const ATTR_CC_EXP = "input[data-cofi-payable-cc-exp]";
const ATTR_CC_TYPE = "input[data-cofi-payable-cc-type]";
const ATTR_ERR_CONTENT = "data-cofi-payable-error-content";
const ATTR_ERR_CONTAINER = "data-cofi-payable-error-container";
const ATTR_CONTAINER_CC = "data-cofi-payable-cc";
const ATTR_CONTAINER_CVV = "data-cofi-payable-cvv";
const ATTR_CONTAINER_EXP = "data-cofi-payable-exp";
const ATTR_ERR_HEADER = "data-cofi-payable-error-header";

const ATTR_SUBMIT = "data-cofi-payable-submit";
const ATTR_SAVE_INFO = "data-cofi-payable-save-info";
const ATTR_CANCEL = "data-cofi-payable-cancel";
const ATTR_TOKEN = "input[data-cofi-payable-auth]";
const SEL_FORM = "form[data-cofi-payable]";

const CODE_UNKNOWN = -99999;

const MAX_WAIT_FOR_SUBMIT_MS = 60000;

class ControlState {
  constructor() {
    this.valid = false;
    this.error = "Uninitialized";
    this.code = CODE_UNKNOWN;
  }
  setValid() {
    this.valid = true;
    this.error = "";
    this.code = 0;
    this.extraInfo = "";
  }
  setInvalid(msg, code, extraInfo = "") {
    this.valid = false;
    this.error = msg;
    this.code = code;
    this.extraInfo = extraInfo;
  }
  get statusSet() {
    return this.code !== CODE_UNKNOWN;
  }
}

class Payable {
  initialize() {
    this.initialized = false;
    if (this.loadIntervalId) {
      clearInterval(this.loadIntervalId);
    }
    var candidates = $(SEL_FORM);
    var self = this; // For Event Handlers
    if (candidates.length == 0) {
      return; // nothing to do
    } else if (candidates.length > 1) {
      console.error("multiple forms found, this is an error! ");
      return;
    }
    this.payableForm = candidates[0];
    this.ccState = new ControlState();
    this.cvvState = new ControlState();
    this.expState = new ControlState();
    this.zipState = new ControlState();
    this.fNameState = new ControlState();
    this.lNameState = new ControlState();


    // find the various controls
    this.inputFirstName = this.findControl(ATTR_F_NAME);
    this.inputLastName = this.findControl(ATTR_L_NAME);
    this.inputZip = this.findControl(ATTR_ZIP);
    this.containerCC = this.findControl(ATTR_CONTAINER_CC);
    this.containerCVV = this.findControl(ATTR_CONTAINER_CVV);
    this.containerExp = this.findControl(ATTR_CONTAINER_EXP);
    this.hiddenCCLastFour = this.findControl(ATTR_CC_LAST_FOUR);
    this.hiddenCCExp = this.findControl(ATTR_CC_EXP);
    this.hiddenCCType = this.findControl(ATTR_CC_TYPE);
    this.btnSubmit = this.findControl(ATTR_SUBMIT);
    this.btnSaveInfo = this.findControl(ATTR_SAVE_INFO);
    this.btnCancel = this.findControl(ATTR_CANCEL);
    this.errorContent = this.findControl(ATTR_ERR_CONTENT);
    this.errorContainer = this.findControl(ATTR_ERR_CONTAINER);
    this.errorHeader = this.findControl(ATTR_ERR_HEADER);
    this.authToken = this.getToken();

    // for figuring out where this failed
    this.paymentFormSubmitted = false;
    this.lastStep = 0;


    this.validateFirstName();
    this.validateLastName();

    if (this.authToken == null) {
      console.error("Authentication Missing or Invalid");
      return;
    }

    if (this.inputZip) {
      this.inputZip.on('blur', () => {
        this.validateZip();
        this.updateErrorState();
        this.updateSubmitState();
        this.updateSaveInfoState();
      });
    } else this.validateZip(); // needed for online pay

    if (this.inputFirstName) {
      this.inputFirstName.on('blur', () => {
        this.validateFirstName();
        this.updateErrorState();
        this.updateSubmitState();
        this.updateSaveInfoState();
      });
    }

    if (this.inputLastName) {
      this.inputLastName.on('blur', () => {
        this.validateLastName();
        this.updateErrorState();
        this.updateSubmitState();
        this.updateSaveInfoState();
      });
    }


    this.setSubmitEventHandler();

    this.loadIntervalId = setInterval(() => {
      if ("undefined" === typeof bluesnap) {
        console.warn(
          "Could not locate BlueSnap Javascript, payments will fail!"
        );
        return;
      }
      bluesnap.hostedPaymentFieldsCreate(this.getBlueSnapConfig());
      clearInterval(this.loadIntervalId);
    }, 500);
  }

  validateZip() {
    if (this.inputZip) {
      const zipValue = this.inputZip.val().trim();

      if (zipValue === '') {
        this.zipState.setInvalid('Zip code is required.', 'zip_empty');
      } else {
        const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
        if (zipRegex.test(zipValue)) {
          this.zipState.setValid();
        } else {
          this.zipState.setInvalid('Please enter a valid zip code.', 'zip_invalid');
        }
      }
    } else {
      this.zipState.setValid(); // needed for online pay
    }
  }

  validateFirstName() {
    if (this.inputFirstName) {
      const firstNameValue = this.inputFirstName.val().trim();

      if (firstNameValue === '') {
        this.fNameState.setInvalid('First name is required.', 'first_name_empty');
      } else {
        this.fNameState.setValid();
      }
    } else {
      this.fNameState.setValid(); // needed for online pay
    }
  }

  validateLastName() {
    if (this.inputLastName) {
      const lastNameValue = this.inputLastName.val().trim();

      if (lastNameValue === '') {
        this.lNameState.setInvalid('Last name is required.', 'last_name_empty');
      } else {
        this.lNameState.setValid();
      }
    } else {
      this.lNameState.setValid(); // needed for online pay
    }
  }

  handleSubmit(e, actionType = "", actionPath = "") {
    const self = this;
    this.lastStep = 0;
    let canceled = false;
    const hungRequestId = setTimeout(() => {
      if (self.lastStep == 2 && !self.paymentFormSubmitted) {
        canceled = true;
        self.displayErrors([
          `This page needs to reload. Please refresh the page and try again.`,
        ]);
        self.resetButtonState();
      }
    }, MAX_WAIT_FOR_SUBMIT_MS);

    try {
      e.preventDefault();
      this.lastStep = 1;
      this.disableAllButtons();

      if (actionPath && this.payableForm) {
        this.payableForm.setAttribute("action", actionPath);
      }

      this.lastStep = 2;
      bluesnap.hostedPaymentFieldsSubmitData((response) => {
        clearTimeout(hungRequestId);
        try {
          if (canceled) {
            console.warn("Request was cancelled");
            return;
          }
          self.lastStep = 10;
          if (null != response.cardData) {
            self.lastStep = 11;
            self.setInputValueIfExists(
              self.hiddenCCLastFour,
              response.cardData.last4Digits
            );
            self.lastStep = 12;
            self.setInputValueIfExists(self.hiddenCCExp, response.cardData.exp);
            self.lastStep = 13;
            self.setInputValueIfExists(
              self.hiddenCCType,
              response.cardData.ccType
            );
            self.lastStep = 14;
            self.paymentFormSubmitted = true;
            window.Rails.fire(this.payableForm, "submit");
            //self.payableForm.submit()
          } else {
            var errorArray = response.error;
            errors = [];
            self.lastStep = 21;
            for (i in errorArray) {
              console.log(
                "Received error: tagId= " +
                errorArray[i].tagId +
                ", errorCode= " +
                errorArray[i].errorCode +
                ", errorDescription= " +
                errorArray[i].errorDescription
              );
              errors.push(errorArray[i].errorDescription);
            }
            self.lastStep = 22;
            self.displayErrors(errors);
            self.lastStep = 23;
            self.resetButtonState();
          }
        } catch (e) {
          console.log(`Failure in submit(inner), ${e.message}`);
          // log error
          if (self.paymentFormSubmitted) {
            console.log("submit(inner) - after form submission");
            self.displayErrors([
              "Payment could not be processed please contact support.",
            ]);
          } else {
            self.displayErrors([
              `We are having trouble contacting the payment processor, please try again. Error Code: ${this.lastStep}`,
            ]);
            self.resetButtonState();
          }
        }
      });
    } catch (e) {
      console.log(`Failure in submit(outer), ${e.message}`);
      this.displayErrors([
        `We are having trouble contacting the payment processor, please try again. Error Code: ${this.lastStep}`,
      ]);
      self.resetButtonState();
    }
  }

  findControl(attr) {
    var sel = attr;
    if (attr.indexOf("[") == -1) {
      sel = "*[" + attr + "]";
    }
    let elem = $(sel);
    if (elem.length == 0 || elem.length > 1) {
      console.error("Found " + elem.length + " elements with attr " + attr);
      return null;
    }
    return elem.first();
  }
  setInputValueIfExists(ctrl, value) {
    if (!ctrl) {
      console.warn(`tried to set ${value} on non existent control`);
      return;
    }
    ctrl.val(value);
  }
  getToken() {
    let elem = $(ATTR_TOKEN);
    if (elem.length > 0) {
      return elem.val();
    }
    return null;
  }
  getBlueSnapConfig() {
    var self = this;
    // see what type of form control we are using

    let fontSize = $("[data-bluesnap='ccn']").css("font-size") || "16px";
    return {
      token: this.authToken,
      ccnPlaceHolder: "1234 5678 9012 3456",
      cvvPlaceHolder: "CVV",
      expPlaceHolder: "MM / YY",
      // event handlers
      onFieldEventHandler: {
        setupComplete: () => {
          self.initialized = true;
          if (self.btnSubmit) {
            self.updateSubmitState();
          }
          if (self.btnSaveInfo) {
            self.updateSaveInfoState();
          }
        },
        onFocus: (tagId) => { },
        onBlur: (tagId) => { },
        onError: (tagId, code, description) => {
          self.setError(tagId, code, description);
        },
        onType: (tagId, cardType, cardData) => {
          // console.log(`onType() - ${cardType}`)
        },
        onValid: (tagId) => {
          self.setValid(tagId);
        },
      },
      style: {
        input: {
          "font-family":
            'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          "font-size": fontSize,
          "font-weight": "400",
          color: "#495057",
        },
        "::-webkit-input-placeholder": {
          color: "#6c757d",
        },
      },
    };
  }
  //we always want to be able to cancel now
  setCancelState(enabled) {
    //console.log(this.btnSubmit)
    //if (!this.setButtonState(this.btnCancel, enabled)) {
    //  console.warn("Not tracking Cancel button state as its undefined");
    //}
  }
  setSubmitState(enabled) {
    if (this.btnSubmit) {
      if (!this.setButtonState(this.btnSubmit, enabled)) {
        console.warn("Failed to set Submit button state");
      }
    } else {
      console.warn("Not tracking Submit button state as it's undefined");
    }
  }
  setSaveInfoState(enabled) {
    if (!this.setButtonState(this.btnSaveInfo, enabled)) {
      console.warn("Not tracking Save Info button state as its undefined");
    }
  }
  disableAllButtons() {
    if (this.btnSubmit) {
      this.setSubmitState(false);
    }
    if (this.btnSaveInfo) {
      this.setSaveInfoState(false);
    }
    this.setCancelState(false);
  }
  resetButtonState() {
    if (this.btnSubmit) {
      this.updateSubmitState();
    }
    if (this.btnSaveInfo) {
      this.updateSaveInfoState();
    }
    this.setCancelState(true);
    this.setSubmitEventHandler();
  }

  setSubmitEventHandler() {
    if (!this.btnSubmit && !this.btnSaveInfo) return;
    const self = this;

    if (this.btnSubmit) {
      this.btnSubmit.off("click").one("click", (e) => {
        self.handleSubmit(e, "payNow");
      });
    }

    if (this.btnSaveInfo) {
      this.btnSaveInfo.off("click").one("click", (e) => {
        const actionPath = $(e.target).data("action-path");
        self.handleSubmit(e, "saveInfo", actionPath);
      });
    }
  }

  setButtonState(btn, enabled) {
    if (!btn) {
      return false;
    }
    if (enabled) {
      btn.removeClass("disabled");
      btn[0].disabled = "";
    } else {
      btn.addClass("disabled");
      btn[0].disabled = "true";
    }
    return true;
  }
  updateSubmitState() {
    if (this.formValid()) {
      this.setSubmitState(true);
    } else {
      this.setSubmitState(false);
    }
  }
  updateSaveInfoState() {
    if (this.formValid()) {
      this.setSaveInfoState(true);
    } else {
      this.setSaveInfoState(false);
    }
  }
  displayErrors(errors) {
    if (this.errorContainer || this.errorContent || false) {
      let list = "";
      errors.forEach((elem) => {
        list += "<li>" + elem + "</li>";
      });
      let content = "<ul>" + list + "</ul>";
      if (this.errorHeader || false) this.errorHeader.removeClass("d-none");
      this.errorContent.html(content);
      this.errorContainer.removeClass("d-none");
    }
  }
  hideErrorMessage() {
    if (this.errorContainer || false) {
      this.errorContainer.addClass("d-none");
    }
    if (this.errorContent || false) {
      this.errorContent.html("");
    }
  }
  setFieldError(elem) {
    if (elem || false) {
      elem.addClass("payment-field-error");
    }
  }
  clearFieldError(elem) {
    if (elem || false) {
      elem.removeClass("payment-field-error");
    }
  }
  updateErrorState() {
    let noErrors = true;
    let errors = [];
    if (!this.ccState.valid && this.ccState.statusSet) {
      noErrors = false;
      errors.push(this.ccState.error);
      this.setFieldError(this.containerCC);
    } else {
      this.clearFieldError(this.containerCC);
    }
    if (!this.cvvState.valid && this.cvvState.statusSet) {
      noErrors = false;
      errors.push(this.cvvState.error);
      this.setFieldError(this.containerCVV);
    } else {
      this.clearFieldError(this.containerCVV);
    }
    if (!this.expState.valid && this.expState.statusSet) {
      noErrors = false;
      errors.push(this.expState.error);
      this.setFieldError(this.containerExp);
    } else {
      this.clearFieldError(this.containerExp);
    }

    if (!this.fNameState.valid && this.fNameState.statusSet) {
      noErrors = false;
      errors.push(this.fNameState.error);
      this.setFieldError(this.inputFirstName);
    } else {
      this.clearFieldError(this.inputFirstName);
    }

    if (!this.lNameState.valid && this.lNameState.statusSet) {
      noErrors = false;
      errors.push(this.lNameState.error);
      this.setFieldError(this.inputLastName);
    } else {
      this.clearFieldError(this.inputLastName);
    }


    if (!this.zipState.valid && this.zipState.statusSet) {
      noErrors = false;
      errors.push(this.zipState.error);
      this.setFieldError(this.inputZip);
    } else {
      this.clearFieldError(this.inputZip);
    }

    console.log(`updateErrorState errors ${noErrors}`);
    if (this.errorContainer || false) {
      if (noErrors) {
        this.hideErrorMessage();
        this.resetButtonState();
      } else {
        this.displayErrors(errors);
      }
    } else {
    }
  }
  formValid() {
    return (
      this.initialized &&
      this.ccState.valid &&
      this.cvvState.valid &&
      this.expState.valid &&
      this.zipState.valid &&
      this.fNameState.valid &&
      this.lNameState.valid
    );
  }
  getStateFromTagId(tagId) {
    switch (tagId) {
      case "ccn":
        return this.ccState;
      case "cvv":
        return this.cvvState;
      case "exp":
        return this.expState;
      default:
        console.warn(`invalid tag id ${tagId}`);
        return null;
    }
  }
  setValid(tagId) {
    var state = this.getStateFromTagId(tagId);
    if (state !== null) {
      state.setValid();
    }
    this.updateErrorState();
    if (this.btnSubmit) {
      this.updateSubmitState();
    }
    if (this.btnSaveInfo) {
      this.updateSaveInfoState();
    }
  }
  setError(tagId, code, description) {
    let state = this.getStateFromTagId(tagId);
    let msg = "";
    switch (code) {
      case "10":
        if (tagId === "ccn") {
          msg = "Invalid Card Number";
        } else if (tagId === "cvv") {
          msg = "Invalid CVV Code";
        } else {
          msg = "Invalid Expiration Date";
        }
        break;
      case "22013":
        msg = "Credit Card Type is not Supported";
        break;
      case "14040":
      case "14041":
      case "400":
        msg = "Authorization has expired please refresh and try again";
        break;
      case "14042":
        msg = "An Internal Error of 14042 has occured please contact support";
        break;
      case "403":
      case "404":
      case "500":
        msg = `An Internal Error code ${code} Has Occurred, please try again later`;
        break;
      default:
        msg = `An Unexpected Error code ${code} has occured, please contact support`;
    }
    console.log(
      `setError() - ${tagId} Msg: ${msg} Code: ${code} Desc ${description}`
    );
    if (state != null) {
      state.setInvalid(msg, code, description);
    }
    this.updateErrorState();
    if (this.btnSubmit) {
      this.updateSubmitState();
    }
    if (this.btnSaveInfo) {
      this.updateSaveInfoState();
    }
  }
}
const PS_INVOICE_STATUS_ATTR = "*[data-payable-updater-invoice-status]";
const PS_PAYEE_TEMPLATE_ATTR = "*[data-payable-updater-##]";
export class PaymentStatusUpdater {
  constructor(statusUrl, callback = null) {
    this.statusUrl = statusUrl;
    this.intervalId = setInterval(() => {
      self = this;
      $.ajax({
        url: this.statusUrl,
        context: this,
        dataType: "json",
      }).done(function (data) {
        self.updateUI(data);
      });
    }, 3000);
    this.callback = callback;
    this.boundUrl = window.location.href;
  }
  updateUI(data) {
    var step = 1;
    var total_steps = data.payees.length;
    $(PS_INVOICE_STATUS_ATTR).text(data.status_human);
    data.payees.forEach((elem) => {
      $(PS_PAYEE_TEMPLATE_ATTR.replace("##", elem.type)).text(elem.status);
      if (
        elem.status === "Approved" ||
        elem.status === "Refunded" ||
        elem.status === "RefundInProcess"
      ) {
        step++;
      }
    });
    if (this.boundUrl !== window.location.href) {
      console.warn(
        "canceling status updates due to page navigation change, probably due to turbolinks"
      );
      clearInterval(this.intervalId);
      this.callback(false, "page_change", data);
    } else if (
      data.status === "paid" ||
      data.status === "failed" ||
      data.status === "pending" ||
      data.status === "unpaid_failed" ||
      data.status === "refunded" ||
      data.status === "partial_refund" ||
      data.status === "refund_in_process"
    ) {
      clearInterval(this.intervalId);
      if (this.callback !== null) {
        this.callback(true, data.status, step, total_steps, data);
      }
    } else {
      if (this.callback !== null) {
        this.callback(false, data.status, step, total_steps, data);
      }
    }
  }
}
export default new Payable();
